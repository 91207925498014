/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');
require('../css/reset.css');
require('../css/slick.scss');
require('../css/app.scss');

require('../js/slick.min');
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it. //done
const $ = require('jquery');
global.InstagramFeed = require('./instagramfeed.min');
console.log('repere');

//Tentative d'inclusion de tinymce -----------------------------------------------------------

/*
import tinymce from 'tinymce'

import 'tinymce/themes/silver';
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/table'
import 'tinymce/plugins/wordcount'

// Initialize
tinymce.init({
    selector: 'textarea',
    plugins: 'paste link autoresize table wordcount',
    setup (editor) {
        editor.on('keyup', e => console.log(editor.getContent()))
    }
});


tinymce.init({
    selector: '#make_article_content',
    theme: 'silver',
    inline: true,
    setup(editor) {
        editor.on('keyup', e => console.log(editor.getContent()))
    }
});

 */

//Sick caroussel----------------------------------------------------------------

$('.carousel-references').slick({
    infinite: true,
    speed: 1000,
    draggable: true,
    cssEase: 'ease-in-out',
    prevArrow: '<div class="prevarrow refarrow"></div>',
    nextArrow: '<div class="nextarrow refarrow"></div>'
});

$('.carousel-article').slick({
    infinite: true,
    speed: 1000,
    cssEase: 'ease-in-out',
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    prevArrow: '<div class="prevarrow"></div>',
    nextArrow: '<div class="nextarrow"></div>'
});

$('.carousel-article-single').slick({
    infinite: true,
    speed: 1000,
    cssEase: 'ease-in-out',
    autoplay: true,
    fade: true,
    arrows: false,
    autoplaySpeed: 5000,
    dots: true,
});

$('.hero_carousel').slick({
    dots: true,
    appendDots: $(".hero"),
    arrows: false,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'ease-in-out',
    autoplay: true,
    autoplaySpeed: 5000
});

//Carousel instagram

let homepage = document.getElementById('insta-feed');

if (homepage) {

    //Modèle récupéré sur https://rudrastyh.com/instagram/get-photos-and-profile-info-pure-javascript.html
    //Merci bien !


    let token = '9662153959.1677ed0.b6065c9e7e1d4f4983fa3df6bc14200b\n',
        num_photos = 10, // maximum 20
        container = homepage,
        scrElement = document.createElement('script');

    window.processResult = function (data) {
        if(typeof data.data == "undefined")
            return;
        console.log(data);
        for (let x = 0; x < data.data.length; x++) {
            container.innerHTML += '<div class="insta_pic"><img src="' + data.data[x].images.low_resolution.url + '" alt="instagram"></div>';
        }
    };

    scrElement.setAttribute('src', 'https://api.instagram.com/v1/users/self/media/recent?access_token=' + token + '&count=' + num_photos + '&callback=processResult');
    scrElement.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(scrElement);
    global.setInstaFeed = function() {
        console.log('setting instafed', $("#insta-feed"));
        new InstagramFeed({
            'username': 'dlcom_',
            'container': document.getElementById("insta-feed"),
            'display_profile': false,
            'display_biography': false,
            'display_gallery': true,
            'callback': function() {
                instaSlick();
            },
            'styling': false,
            'items': 8,
            'items_per_row': 4,
            'margin': 1
        });
    };
    global.instaSlick = function() {
        $('#insta-feed .instagram_gallery').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            prevArrow: '<div class="prevarrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.57 12.3"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:0.95px;}</style></defs><title>insta_prevarrow</title><g id="Calque_2" data-name="Calque 2"><g id="HOME"><!--<line class="cls-1" x1="28.57" y1="6.15" x2="1.43" y2="6.15"/>--><polyline class="cls-1" points="6.49 11.96 0.67 6.15 6.49 0.34"/></g></g></svg></div>',
            nextArrow: '<div class="nextarrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.57 12.3"><defs><style>.cls-1{fill:none;stroke:#b1b3b4;stroke-miterlimit:10;stroke-width:0.95px;}</style></defs><title>insta_nextarrow</title><g id="Calque_2" data-name="Calque 2"><g id="HOME"><!--<line class="cls-1" y1="6.15" x2="27.14" y2="6.15"/>--><polyline class="cls-1" points="22.08 0.34 27.89 6.15 22.08 11.96"/></g></g></svg></div>',
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 476,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },

            ]
        });
    };
    setInstaFeed();

    let insta = setInterval(function (e) {
        if (homepage && homepage.innerHTML === '') {
            console.log('En attente de instagram')
        } else {
            instaSlick();
            clearInterval(insta);
        }
    }, 1000);
    
    $(function () {
        setHomeMargins();
    });
    $(window).on('resize', function() {
        setHomeMargins();
    });

    function setHomeMargins() {
        if($(window).width() < 1920) {
            $(".hero_carousel").css("margin-bottom", "3em");
            return;
        }
        let he = $("#topWindow").outerHeight() + $(".hero .slick-list").outerHeight();
        let hphe = $("#hpmenu").outerHeight();
        let remaining = $(window).height() - he - hphe;
        console.log('ajustement marges', he, hphe, remaining);
        if(remaining > 96)
            $(".hero_carousel").css("margin-bottom", Math.floor(remaining/2) + "px");
    }
}

//Events au scroll -------------------------------------------------------------------------


let beacon = document.querySelectorAll('.trigger');
let scrolling = window.scrollY;

let html = document.querySelector('html');
let body = document.querySelector('body');

let progression = function (elem) {
    let position = scrolling + elem.getBoundingClientRect().top;
    let positionX = scrolling + elem.getBoundingClientRect().right;

    return (
        scrolling >= position + window.innerHeight * -.75 &&
        positionX <= window.innerWidth
    );
};

let ref = document.querySelectorAll('.ref .trigger');
let refarrows = document.getElementsByClassName('refarrow');

let carousel = function (e) {
    for (let k = 0; k < ref.length; k++) {
        if (progression(ref[k])) {
            if (!ref[k].classList.contains("triggered")) {
                ref[k].classList.add("triggered")
            }
        }
    }
};

for (let i = 0; i < refarrows.length; i++) {
    refarrows[i].addEventListener('click', function () {
        console.log("next");
        for (let k = 0; k < ref.length; k++) {
            setTimeout(function () {
                if (progression(ref[k])) {
                    if (!ref[k].classList.contains("triggered")) {
                        ref[k].classList.add("triggered")
                    }
                }
            }, 800)
        }
    });
}

for (let i = 0; i < beacon.length; i++) {
    if (window.pageYOffset > 0) {
        beacon[i].classList.add("triggered")
    } else {

        document.addEventListener('scroll', function () {
            if (progression(beacon[i])) {
                beacon[i].classList.add("triggered")
            } /* else if (beacon[i].classList.contains("triggered")) {
                beacon[i].classList.remove("triggered")
            } */
        });
    }
}


//Succession des paragraphes de présentation

let intro = document.getElementById('introduction');
let boxes = document.querySelectorAll('.intro_box');
let marks = document.getElementsByClassName("box_mark");
let count = 0;


/*
if (intro) {

    let halt = function () {
        if (intro.getBoundingClientRect().bottom <= window.innerHeight * .5) {
            document.removeEventListener('scroll', halt);
            document.removeEventListener('wheel', halt);
            boxes[0].classList.add('active');
            marks[0].classList.add('active');
        } else {
            if (intro.getBoundingClientRect().top <= 0) {
                document.removeEventListener('scroll', halt);
                html.style.overflowY = "hidden";
                html.style.paddingRight = "16px";

                if (count < boxes.length) {

                    if (boxes[count] === boxes[0]) {
                        boxes[count].classList.add('active');
                        marks[count].classList.add('active');
                    } else {
                        boxes[0].classList.remove('active');
                        marks[0].classList.remove('active');

                        boxes[count - 1].classList.remove('active');
                        marks[count - 1].classList.remove('active');

                        boxes[count].classList.add('active');
                        marks[count].classList.add('active');
                    }
                    count++;
                    document.removeEventListener('wheel', halt);
                    setTimeout(function () {
                        document.addEventListener('wheel', halt)
                    }, 1500)

                } else {
                    html.style.overflowY = "scroll";
                    html.style.paddingRight = "0";
                    document.removeEventListener('wheel', halt);
                }
            }
        }
    };

    if (window.innerWidth > 476) {
        document.addEventListener('scroll', halt);
    } else {
        boxes[0].classList.add('active');
        marks[0].classList.add('active');
    }

    let pass = document.getElementById("pass");

    pass.addEventListener('click', function (e) {
        html.style.overflowY = "scroll";
        html.style.paddingRight = "0";
        document.removeEventListener('wheel', halt);
        document.removeEventListener('scroll', halt);
    })

}


 */

//Navigation manuelle dans l'introduction------------------------------

/*

let group = document.querySelectorAll("[data-link]");

for (let i = 0; i < marks.length; i++) {
    marks[i].addEventListener('click', function (e) {

        let target = e.target;

        target.classList.add('active');

        let link = marks[i].dataset.link;

        for (let i = 0; i < group.length; i++) {

            if (group[i].dataset.link === link) {
                if (!group[i].classList.contains('active')) {
                    group[i].classList.add('active');
                }

            } else {
                if (group[i].classList.contains('active')) {
                    group[i].classList.remove('active')
                }
            }
        }

    })
}

 */


//Animation du header-----------------------------------------------

let header = document.getElementById('topWindow');

let animHeader = function (e) {
    let scrolling = window.scrollY;
    if (scrolling > 0) {
        header.classList.add('movible');
    } else {
        header.classList.remove('movible');
    }
};

document.addEventListener('scroll', animHeader);

let loader = document.getElementsByClassName('loader');
let box = document.getElementById('box');
let couvercle = document.getElementById('couvercle');
let socle = document.getElementById('socle');
let lien = document.getElementById('lien');

function hideLoader() {
    setTimeout(function () {
        loader[0].style.opacity = '0';
        loader[0].style.zIndex = '-1';
        box.style.opacity = "0";
    }, 1000);
}
hideLoader();
window.onpopstate = function() {
    console.log('reperepop');
};
window.onpageshow = function(event) {
    if (event.persisted) {
        console.log("From bfcache");
        hideLoader();
    }
    else
        console.log("Not from bfcache");
};
window.onloadstart = function(event) {
        console.log("startload");
};
window.onhashchange = function(event) {
    console.log("hash change");
};
window.onload = function(event) {
    console.log("load");
};
window.onloadstart = function(event) {
    console.log("onloadstart");
};
window.onloadend = function(event) {
    console.log("onloadend");
};
// Transition de pages ------------------------------------------------------------

let links = document.querySelectorAll('a:not([target="_blank"])');

for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (e) {
        let href = links[i].href;

        if (href.indexOf('#') <= -1) {
            e.preventDefault();

            loader[0].style.zIndex = '10';
            loader[0].style.opacity = '1';
            loader[0].style.transition = 'opacity 1s ease-in-out, z-index .1s .1s'

            setTimeout(function () {
                window.location.href = href;
            }, 1000);
        }
    });
}

//Formulaire de contact -------------------------------------------------

let formulaire = document.getElementById('formulaire_contact');
let contact = document.getElementById('contact');
let retour = document.getElementById('return');

contact.addEventListener('click', function () {
    formulaire.classList.add('open')
});
$("#hpcontact").on('click', function() {
    console.log('clic mnu');
    $("#toggle_menu").click();
    $("#formulaire_contact").addClass('open');
});

retour.addEventListener('click', function () {
    formulaire.classList.remove('open')
});

//Bouton "Exposer votre projet"

let exposer = document.getElementById('project_contact');
let menubox = document.getElementById('menu_box');

if (exposer) {

    exposer.addEventListener('click', function (e) {
        e.preventDefault();
        menubox.checked = 'true';
        formulaire.classList.add('open');
    });
}

// Sur page des références -------------------------------------------------
document.addEventListener('DOMContentLoaded', function () {
    let scrolling = window.scrollY;
    if (scrolling > 0) {
        header.classList.add('movible');
    }
    carousel();
});

// Conservation des animations au drag sur le carousel des références--------
document.addEventListener('mousedown', function () {
    document.addEventListener("mouseup", function () {
        setTimeout(carousel, 1000)
    });
});

    document.addEventListener("touchmove", function () {
        setTimeout(carousel, 1000)
    });

// Halo lumineux autour de la souris : script inspiré de https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp

function enlight(imgID) {
    let img, light, w, h, bw;
    img = imgID;

    light = document.getElementById('light');

    w = light.offsetWidth / 2;
    h = light.offsetHeight / 2;

    /* Execute a function when someone moves the magnifier glass over the image: */
    light.addEventListener("mousemove", moveLight);
    img.addEventListener("mousemove", moveLight);

    function moveLight(e) {
        let pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        /* Get the cursor's x and y positions: */
        pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;

        light.style.left = (x - w) + "px";
        light.style.top = (y - h) + "px";
    }

    function getCursorPos(e) {
        let a, x = 0, y = 0;

        /* Get the x and y positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x: x, y: y};
    }
}

let hero = document.getElementsByClassName('hero')[0];

if ( false && hero) {
    enlight(hero);
}

// Navigation en page référence single-------------------------------------------------------

let navRef = document.getElementsByClassName('nav_ref')[0],
    righty = document.getElementsByClassName('righty')[0],
    lefty = document.getElementsByClassName('lefty')[0];

if (navRef) {
    righty.addEventListener('click', function () {
        navRef.style.transform = 'translateX(-50%)';
    });

    lefty.addEventListener('click', function () {
        navRef.style.transform = 'translateX(50%)'
    });
}



